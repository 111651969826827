// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-js": () => import("/opt/build/repo/src/Templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-product-listing-page-js": () => import("/opt/build/repo/src/Templates/ProductListingPage.js" /* webpackChunkName: "component---src-templates-product-listing-page-js" */),
  "component---src-templates-product-detail-page-js": () => import("/opt/build/repo/src/Templates/ProductDetailPage.js" /* webpackChunkName: "component---src-templates-product-detail-page-js" */),
  "component---src-templates-data-products-page-js": () => import("/opt/build/repo/src/Templates/DataProductsPage.js" /* webpackChunkName: "component---src-templates-data-products-page-js" */),
  "component---src-templates-order-detail-page-js": () => import("/opt/build/repo/src/Templates/OrderDetailPage.js" /* webpackChunkName: "component---src-templates-order-detail-page-js" */),
  "component---src-templates-profile-page-js": () => import("/opt/build/repo/src/Templates/ProfilePage.js" /* webpackChunkName: "component---src-templates-profile-page-js" */),
  "component---src-templates-voice-page-js": () => import("/opt/build/repo/src/Templates/VoicePage.js" /* webpackChunkName: "component---src-templates-voice-page-js" */),
  "component---src-templates-about-us-page-js": () => import("/opt/build/repo/src/Templates/AboutUsPage.js" /* webpackChunkName: "component---src-templates-about-us-page-js" */),
  "component---src-templates-faq-page-js": () => import("/opt/build/repo/src/Templates/FAQPage.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-cart-page-js": () => import("/opt/build/repo/src/Templates/CartPage.js" /* webpackChunkName: "component---src-templates-cart-page-js" */),
  "component---src-templates-checkout-page-js": () => import("/opt/build/repo/src/Templates/CheckoutPage.js" /* webpackChunkName: "component---src-templates-checkout-page-js" */),
  "component---src-templates-validation-page-js": () => import("/opt/build/repo/src/Templates/ValidationPage.js" /* webpackChunkName: "component---src-templates-validation-page-js" */),
  "component---src-templates-expired-page-js": () => import("/opt/build/repo/src/Templates/ExpiredPage.js" /* webpackChunkName: "component---src-templates-expired-page-js" */),
  "component---src-templates-terms-of-service-page-js": () => import("/opt/build/repo/src/Templates/TermsOfServicePage.js" /* webpackChunkName: "component---src-templates-terms-of-service-page-js" */),
  "component---src-templates-privacy-policy-page-js": () => import("/opt/build/repo/src/Templates/PrivacyPolicyPage.js" /* webpackChunkName: "component---src-templates-privacy-policy-page-js" */),
  "component---src-templates-social-login-result-page-js": () => import("/opt/build/repo/src/Templates/SocialLoginResultPage.js" /* webpackChunkName: "component---src-templates-social-login-result-page-js" */),
  "component---src-templates-select-cvs-page-js": () => import("/opt/build/repo/src/Templates/SelectCvsPage.js" /* webpackChunkName: "component---src-templates-select-cvs-page-js" */)
}

